import React from 'react'
import {Footer, SEO} from '../components'
import FAQ from '../components/FAQ'

const pageCertificate = {
  jobCode: 'GB-PNX-00430',
  dateOfPrep: 'February 2024',
}

const Faq = () => (
  <>
    <SEO title="Frequently asked questions | MSD" path="faq" />
    <main className="content">
      <FAQ />
    </main>
    <Footer certificate={pageCertificate} />
  </>
)

export default Faq

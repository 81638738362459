import React from 'react'
import Video from '../../Video'

interface Props {
  videos: string[]
}

const Videos: React.FC<Props> = ({videos}) => (
  <div className="d-flex flex-wrap justify-content-center gap-4">
    {videos.map((video, index) => (
      <Video key={index} maxWidth={400} iframe={video} />
    ))}
  </div>
)

export default Videos

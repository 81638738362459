import React from 'react'
import {Accordion} from 'react-bootstrap'
import parse from 'html-react-parser'

import Feedback from '../Feedback'
import Videos from '../AnswerSections/Videos'
import List from '../AnswerSections/List'
import ExternalLink from '../AnswerSections/ExternalLink'
import IconText from '../AnswerSections/IconText'
import Icon from '../AnswerSections/Icon'

import * as styles from './Question.module.scss'

import {AnswersData, AnswerSection} from '../models/answers.model'

interface Props {
  data: AnswersData
  activeKey: string
  searchText: string
}

const Question: React.FC<Props> = ({data, activeKey, searchText}) => {
  const {id, question, answer: answerSections} = data

  // Function to highight search text
  const getHighlightedText = (text: string) => {
    const parts = text.split(new RegExp(`(${searchText})`, 'gi'))

    if (searchText.length < 1) {
      return text
    }

    return parts
      .map((part) =>
        part.toLowerCase() === searchText.toLowerCase()
          ? `<span class="${styles.highlighted}">${part}</span>`
          : `<span>${part}</span>`,
      )
      .join('')
  }

  const getAnswerSectionBasedOnTypes = (item: AnswerSection) => {
    switch (item.type) {
      case 'text':
      case 'text-inline':
        return (
          <p className={item.type === 'text' ? '' : 'd-inline'}>
            {parse(getHighlightedText(item.content))}
          </p>
        )
      case 'list':
        return (
          <List
            title={item.title && getHighlightedText(item.title)}
            items={item.items}
          />
        )
      case 'external-link':
        return <ExternalLink content={getHighlightedText(item.content)} />
      case 'icon-text':
        return (
          <IconText
            content={getHighlightedText(item.text)}
            iconName={item.iconName}
            alt={item.alt}
          />
        )
      case 'icon':
        return (
          <Icon
            className="mb-4 mx-auto"
            iconName={item.iconName}
            width={400}
            parseSvg
          />
        )
      case 'videos':
        return <Videos videos={item.videos} />
      default:
        return null
    }
  }

  return (
    <Accordion.Item id={id} eventKey={activeKey}>
      <Accordion.Header className={styles.header}>
        <span className={styles.title}>
          {parse(getHighlightedText(question))}
        </span>
      </Accordion.Header>

      <Accordion.Body>
        {answerSections.map((item: AnswerSection) =>
          getAnswerSectionBasedOnTypes(item),
        )}
        <Feedback question={question} />
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default Question

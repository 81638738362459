import React from 'react'
import parse from 'html-react-parser'
import Icon from './Icon'
interface Props {
  iconName: string
  alt: string
  content: string
}

const IconText: React.FC<Props> = ({iconName, alt, content}) => {
  return (
    <div className="d-flex jusify-content-center align-items-center gap-3 pb-3 ps-3">
      <Icon iconName={iconName} width={80} alt={alt} />
      <p>{parse(content)}</p>
    </div>
  )
}

export default IconText

import React from 'react'
import parse from 'html-react-parser'
interface Props {
  items: string[]
  title?: string
}
const List: React.FC<Props> = ({title, items}) => (
  <>
    {title && <p className="mb-2">{parse(title)}</p>}

    <ul>
      {items.map((listItem: string) => (
        <li key={listItem}>{parse(listItem)}</li>
      ))}
    </ul>
  </>
)

export default List

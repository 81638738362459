import React, {useState} from 'react'
import {Container, Accordion} from 'react-bootstrap'
import Question from './Question'
import SearchInput from './SearchInput'
import {heading} from './styles.module.scss'
import {AnswersData} from './models/answers.model'
import _faqData from './data.json'

const faqData = _faqData as AnswersData[]

// Return true if question or answer countains search keywords
const search = (data: any, keywords: string): boolean => {
  for (const key in data) {
    if ({}.hasOwnProperty.call(data, key)) {
      const value = data[key]
      if (
        typeof value === 'string' &&
        value.toLowerCase().indexOf(keywords.toLowerCase()) > -1
      )
        return true

      if (typeof value === 'object') {
        const result = search(value, keywords)
        if (result) return true
      }
    }
  }
  return false
}

const FAQ: React.FC = () => {
  const [searchText, setSearchText] = useState<string>('')

  const filteredData = faqData.filter((data) => search(data, searchText))

  return (
    <section id="faq">
      <div className={heading}>
        <Container className="py-5">
          <h1>
            Frequently
            <br />
            <span className="text-secondary">Asked</span> Questions
          </h1>
          <p>Browse the list or type your question in the search box.</p>
          <div className="d-flex justify-content-center py-3">
            <SearchInput onChange={setSearchText} />
          </div>
        </Container>
      </div>

      <Container className="py-5">
        <Accordion alwaysOpen flush>
          {filteredData.map((data: AnswersData, index: number) => (
            <Question
              {...{data, searchText}}
              activeKey={index.toString()}
              key={data.id}
            />
          ))}
        </Accordion>
      </Container>
    </section>
  )
}

export default FAQ

import React, {useState} from 'react'
import {
  feedback,
  feedbackButton,
  thumbsUp,
  thumbsDown,
  votedUp,
  votedDown,
  disabled,
} from './feedback.module.scss'

interface Props {
  question: string
}

enum VoteType {
  UP = 'thumbs up',
  DOWN = 'thumbs down',
}

declare global {
  interface Window {
    dataLayer: {
      push: Function
    }
  }
}

/* eslint-disable consistent-return */

const Feedback: React.FC<Props> = ({question}) => {
  const handleFeedback = (feedbackType: VoteType) => {
    if (typeof window !== 'undefined' && !localStorage.getItem(question)) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'gaevent',
        event_label: question,
        event_category: feedbackType,
        event_action: 'click',
      })
      localStorage.setItem(question, feedbackType)
      handleVote(feedbackType)
    }
  }

  const [isUpVoted, setUpVoted] = useState(false)
  const [isDownVoted, setDownVoted] = useState(false)

  const handleVote = (buttonType: string) => {
    if (buttonType === VoteType.UP) {
      setUpVoted(!isUpVoted)
    } else if (buttonType === VoteType.DOWN) {
      setDownVoted(!isDownVoted)
    }
  }

  const setVoteClasses = (voteType: VoteType) => {
    const voteDirectionClass = voteType === VoteType.UP ? votedUp : votedDown

    if (typeof window !== 'undefined' && localStorage.getItem(question)) {
      return localStorage.getItem(question) === voteType
        ? voteDirectionClass
        : disabled
    }
    return ''
  }

  const checkLocalStorage = (voteType: VoteType) => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(question) === voteType
    }
  }

  return (
    <div className={feedback}>
      <span>Did this answer your question?</span>&nbsp;
      <button
        disabled={isUpVoted || checkLocalStorage(VoteType.UP)}
        className={`${thumbsUp} ${feedbackButton} ${
          isUpVoted || setVoteClasses(VoteType.UP)
        }`}
        onClick={() => handleFeedback(VoteType.UP)}
      >
        &nbsp;
      </button>
      <button
        disabled={isDownVoted || checkLocalStorage(VoteType.DOWN)}
        className={`${thumbsDown} ${feedbackButton} ${
          isDownVoted || setVoteClasses(VoteType.DOWN)
        }`}
        onClick={() => handleFeedback(VoteType.DOWN)}
      >
        &nbsp;
      </button>
    </div>
  )
}

export default Feedback

import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import parse from 'html-react-parser'

interface Props {
  iconName: string
  width: number
  alt?: string

  className?: string
  parseSvg?: boolean
}

const Icon: React.FC<Props> = (props) => {
  const {iconName, width, alt, className, parseSvg = false} = props

  const {
    allFile: {nodes: icons},
  } = useStaticQuery<Queries.GetIconUrlsQuery>(query)

  const img = icons.find((icon_) => icon_.name === iconName)
  if (!img?.publicURL || !img.svg?.content) return null

  return (
    <div className={className} style={parseSvg ? {maxWidth: width} : {}}>
      {parseSvg ? (
        parse(img.svg?.content)
      ) : (
        <img src={img.publicURL} alt={alt} width={width} />
      )}
    </div>
  )
}

export default Icon

export const query = graphql`
  query GetIconUrls {
    allFile(filter: {relativeDirectory: {eq: "icons"}}) {
      nodes {
        name
        publicURL
        svg {
          content
        }
      }
    }
  }
`

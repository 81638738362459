import React, {useState} from 'react'
import {Button} from 'react-bootstrap'
import ExternalLinkPopUp from '../../../ExternalLinkPopUp'
import {link} from './ExternalLink.module.scss'

interface Props {
  content: string
}

const ExternalLink: React.FC<Props> = ({content}) => {
  const [showExternalLinkPopUp, setShowExternalLinkPopUp] = useState(false)

  return (
    <>
      <Button
        as="a"
        onClick={() => setShowExternalLinkPopUp(true)}
        className={link}
      >
        {content}
      </Button>
      <ExternalLinkPopUp
        show={showExternalLinkPopUp}
        handleClose={() => setShowExternalLinkPopUp(false)}
        link={content}
      />
    </>
  )
}

export default ExternalLink

import React from 'react'
import {box, input, icon} from './styles.module.scss'

interface Props {
  onChange: (searchText: string) => void
}

const SearchInput: React.FC<Props> = ({onChange}) => (
  <div className={box}>
    <input
      type="text"
      name="search-box"
      placeholder="Search"
      className={input}
      onChange={(e) => onChange(e.currentTarget.value)}
    />
    <div className={icon}>&nbsp;</div>
  </div>
)

export default SearchInput
